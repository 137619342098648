import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ThankYouPage = () => {
  return (
    <Layout>
      <div>
        <h1>Thank you for contacting us!</h1>
        <p>We appreciate your interest in our services and will get back to you as soon as possible.</p>
      </div>
    </Layout>

  )
}
export const Head = () => <Seo title="Thank you!"/>
export default ThankYouPage
